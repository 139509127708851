<template>
  <div class="card col-12">
    <div class="card-header border-0">
      <div class="col-8">
        <h3>{{ title }}</h3>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-1 text-center">

      </div>
      <div class="col-6">
        <p>Analista</p>
      </div>
      <div class="col-5 text-center">
        <p>Confirmações de embarque</p>
      </div>
    </div>
    <div v-for="item in rowsData" :key="item.idresponsavel" class="card-body p-0">
      <div class="row col-12 px-2 pt-4 pb-2 bt-1 m-0">
        <div class="col-1 pl-0 text-center" v-html="getMedia(item.total)"></div>
        <div class="col-6 pl-0">
          <p class="name">{{ item.nome_analista }}</p>
        </div>
        <div class="col-5 px-0 text-center">
          <p>{{ item.total }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SimpleEmbarqueList',
  data () {
    return {

    }
  },
  components: {

  },
  props: {
    title: {
      Type: String
    },
    rowsData: {
      Type: Object
    },
    rowsMedia: {
      Type: String
    }
  },
  mounted () {

  },
  methods: {
    getMedia (val) {
      if ((this.rowsMedia - val) > 0) {
        return '<small class="down ml-2" style="background: #eb6e6e;border-radius: 50%;color: #fff;float: right;width: 30px;height: 30px;margin-top: -5px;padding-top: 7px;" title="' + Math.abs(this.rowsMedia - val) + ' processos abaixo da média que é ' + this.rowsMedia + '">- ' + Math.abs(this.rowsMedia - val) + '</small>'
      } else {
        return '<small class="up ml-2" style="background: #449944;border-radius: 50%;color: #fff;float: right;width: 30px;height: 30px;margin-top: -5px;padding-top: 7px;" title="' + Math.abs(this.rowsMedia - val) + ' processos acima da média que é ' + this.rowsMedia + '">+ ' + Math.abs(this.rowsMedia - val) + '</small>'
      }
    }
  },
  watch: {

  }
}
</script>

<style scoped>
.bt-1 {
  border-top: 1px solid #cecece;
}
.badge {
  background: #CEDABF;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  float: left;
  margin-top: -12px;
  padding: 11px 14px;
  font-weight: bold;
  color: #fff;
}
.down {
  background: #eb6e6e;
  border-radius: 40%;
  padding: 5px 3px;
  color: #fff;
}
.up {
  background: #449944;
  border-radius: 40%;
  padding: 5px 3px;
  color: #fff;
}
</style>
