<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>
    <div class="col-12 col-md-12">
      <UserBar :period="period" :OPAnalyst="OPAnalyst" v-on:changePeriod="updatePeriod($event)"></UserBar>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <SimpleEmbarqueList
      :title="'Importação Marítima'"
      :rowsData="operationalImpo"
      :rowsMedia="getMedia(operationalImpoMedia[0], this.operationalImpo)"
      ></SimpleEmbarqueList>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <SimpleEmbarqueList
      :title="'Exportação Marítima'"
      :rowsData="operationalExpo"
      :rowsMedia="getMedia(operationalExpoMedia[0], this.operationalExpo)"
      ></SimpleEmbarqueList>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <SimpleEmbarqueList
      :title="'Aéreo'"
      :rowsData="operationalAereo"
      :rowsMedia="getMedia(operationalAereoMedia[0], this.operationalAereo)"
      ></SimpleEmbarqueList>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <SimpleEmbarqueList
      :title="'Rodoviário'"
      :rowsData="operationalRodoviario"
      :rowsMedia="getMedia(operationalRodoviarioMedia[0], this.operationalRodoviario)"
      ></SimpleEmbarqueList>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import KpiService from '@/services/KPI/KpiService'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import UserBar from '@/components/Kpi/UserBar.vue'

import SimpleEmbarqueList from '@/components/Kpi/SimpleEmbarqueList.vue'

export default {
  name: 'OperationalEmbarques',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' ' + this.$i18n.t('kpi.analyst.operatinalList') + ' - %s'
    }
  },
  data () {
    return {
      period: '',
      OPAnalyst: false,
      isLoading: false,
      fullPage: true,
      operationalList: [],
      operationalImpo: [],
      operationalExpo: [],
      operationalAereo: [],
      operationalRodoviario: [],
      operationalListMedia: [],
      operationalImpoMedia: [],
      operationalExpoMedia: [],
      operationalAereoMedia: [],
      operationalRodoviarioMedia: []
    }
  },
  components: {
    Loading,
    UserBar,
    SimpleEmbarqueList
  },
  beforeMount () {

  },
  mounted () {
    this.getAnalystList()
  },
  methods: {
    getAnalystList () {
      this.isLoading = true

      KpiService.getOperationalActivityList(this.period).then(res => {
        this.operationalList = res.data.data
        this.operationalListMedia = res.data.media

        this.operationalImpo = this.operationalList.filter((item) => {
          return item.setor === 'Importação Marítima'
        })

        this.operationalExpo = this.operationalList.filter((item) => {
          return item.setor === 'Exportação Marítima'
        })

        this.operationalAereo = this.operationalList.filter((item) => {
          return item.setor === 'Aéreo'
        })

        this.operationalRodoviario = this.operationalList.filter((item) => {
          return item.setor === 'Rodoviário'
        })

        this.operationalImpoMedia = this.operationalListMedia.filter((item) => {
          return item.setor === 'Importação Marítima'
        })

        this.operationalExpoMedia = this.operationalListMedia.filter((item) => {
          return item.setor === 'Exportação Marítima'
        })

        this.operationalAereoMedia = this.operationalListMedia.filter((item) => {
          return item.setor === 'Aéreo'
        })

        this.operationalRodoviarioMedia = this.operationalListMedia.filter((item) => {
          return item.setor === 'Rodoviário'
        })

        this.isLoading = false
      })
    },
    getMedia (media, value) {
      if (media) {
        return Math.round(media.qtd / value.length)
      }
    },
    updatePeriod (periodUpdated) {
      this.period = periodUpdated
    }
  },
  computed: {

  },
  watch: {
    period: {
      handler () {
        this.getAnalystList()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
